/* eslint-disable react/display-name */
import React, { memo } from "react";
import { graphql } from "gatsby";
import { injectIntl } from "gatsby-plugin-react-intl";

import Loadable from "react-loadable";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Loading from "../components/loading";
import GenericPage from "../components/generic-page";

const DynamicPage = memo(function DynamicPage({
  data,
  pageContext: { properties = {}, eventCategories, operatorCategories, localizedSlugs = {} },
}) {
  const {
    contentSections,
    seoKeywords,
    siteNameNavigation,
    seoDescription,
    noIndex,
  } = data?.contentfulSivu;

  const {
    siteSettings,
    articles,
    events,
    eventsFilteredByCategories,
    operatorsFilteredByCategory: { nodes: filteredOperators },
  } = data;

  const getContentSectionType = (node) => {
    if (node?.type) return node?.type; // Content section (Listaosio)

    if (node?.pullupName) return "pullup"; // Pullup (Nosto)

    if (node?.formName) return "generic-form"; // Generic form (Lomake)

    return null;
  };

  return (
    <Layout data={data} properties={properties} localizedSlugs={localizedSlugs}>
      <SEO
        keywords={seoKeywords}
        title={siteNameNavigation}
        description={seoDescription?.seoDescription}
        noIndex={noIndex}
      />
      <section className="flex flex-wrap lg:mt-0 content-section">
        <GenericPage articlePosts={articles} data={data?.contentfulSivu} />

        {contentSections && (
          <div className="w-full">
            {contentSections.map((node) => {
              const type = getContentSectionType(node);
              if (!type) return null;

              const categoryNames = node.articleCategories?.map(({ categoryName }) => categoryName);

              const withFullScreenLoader = [
                "hero",
                "event-cards-combined",
                "article-cards-paginated",
              ];

              const loadingPlaceholderClassNames = withFullScreenLoader.includes(type)
                ? "h-screen-menus sm:h-screen items-center"
                : "mt-12";

              const Module = Loadable({
                loader: () => import(`../components/${type}`),
                loading() {
                  return <Loading className={loadingPlaceholderClassNames} />;
                },
              });
              return (
                <Module
                  key={node?.id}
                  node={node}
                  heading={node?.heading ?? node?.pullupName}
                  content={node?.content}
                  currentPageSlug={data?.contentfulSivu?.slug}
                  pickedPullups={node?.pickedPullups}
                  siteSettings={siteSettings}
                  articlePosts={articles}
                  events={eventCategories?.length > 0 ? eventsFilteredByCategories : events}
                  categories={categoryNames}
                  eventCategoryIds={eventCategories}
                  operatorCategories={operatorCategories}
                  filteredOperators={filteredOperators}
                  showEventFilters={node?.showEventFilters ?? false}
                  linkButton={node?.linkButton}
                />
              );
            })}
          </div>
        )}
      </section>
    </Layout>
  );
});

export default injectIntl(DynamicPage);

export const pageQuery = graphql`
  query(
    $slug: String!
    $locale: String
    $eventCategories: [String!]
    $operatorCategories: [String!]
  ) {
    contentfulSivu(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      __typename
      id
      slug
      siteNameNavigation
      seoKeywords
      seoDescription {
        seoDescription
      }
      noIndex
      heading
      ingress {
        ingress
      }
      carousel {
        carouselImages {
          ... on ContentfulAsset {
            id
            description
            gatsbyImageData(layout: CONSTRAINED, width: 1200, aspectRatio: 1.77777778)
          }
        }
        wide
      }
      headImage {
        description
        gatsbyImageData(width: 1500, layout: CONSTRAINED, aspectRatio: 1.77777778)
      }
      headImageMobile: headImage {
        description
        gatsbyImageData(layout: CONSTRAINED, width: 800, aspectRatio: 0.8)
      }
      content {
        raw
        references {
          ...RichTextReferencesFragment
        }
      }
      subNavigation {
        navigationLinks {
          ... on Node {
            ... on ContentfulSivu {
              __typename
              siteNameNavigation
              slug
            }
            ... on ContentfulLinkki {
              __typename
              linkName
              link
            }
            ... on ContentfulAistikategoria {
              __typename
              categoryName
              slug
            }
          }
        }
      }
      contentSections {
        ... on Node {
          ... on ContentfulLomake {
            id
            formName
            thankYouPageReference {
              slug
            }
            formFields {
              ... on Node {
                ... on ContentfulLomakkeenKentta {
                  fieldLabel
                  fieldName
                  required
                  type
                  options
                }
              }
            }
          }
          ... on ContentfulListaosio {
            __typename
            id
            type
            heading
            slogan
            articleCategories {
              categoryName
            }
            bannerColors
            showEventFilters
            pickedPullups {
              ... on Node {
                ... on ContentfulNosto {
                  __typename
                  id
                  pullupName
                  image {
                    description
                    gatsbyImageData(aspectRatio: 1.77777778, layout: CONSTRAINED, height: 1200, quality: 75)
                  }
                  imageSecondary {
                    description
                    gatsbyImageData(aspectRatio: 1.77777778, layout: CONSTRAINED, height: 1200, quality: 75)
                  }
                }
                ... on ContentfulSivu {
                  __typename
                  id
                  slug
                  siteNameNavigation
                  headImage {
                    description
                    gatsbyImageData(aspectRatio: 1.77777778, layout: CONSTRAINED, width: 800)
                  }
                  headImage_3_4: headImage {
                    description
                    gatsbyImageData(aspectRatio: 0.75, layout: CONSTRAINED, width: 800 )
                  }
                  headImageVertical {
                    gatsbyImageData(aspectRatio: 1.77777778, layout: CONSTRAINED, width: 800)
                  }
                  headImageVertical_3_4: headImageVertical {
                    gatsbyImageData(aspectRatio: 0.75, layout: CONSTRAINED, width: 800 )
                  }
                }
                ... on ContentfulArtikkeli {
                  __typename
                  id
                  slug
                  articleName
                }
              }
            }
            linkButton {
              ... on Node {
                ... on ContentfulSloganlinkki {
                  name
                  page {
                    ... on Node {
                      ... on ContentfulSivu {
                        __typename
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
          ... on ContentfulNosto {
            id
            contentful_id
            pullupName
            content {
              raw
              references {
                ...RichTextReferencesFragment
              }
            }
          }
        }
      }
    }
    ...EventsFragment
    ...EventsFilteredByCategories
    ...OperatorsFilteredByCategories
    ...ArticlesFragment
    ...AllFragment
  }
`;
