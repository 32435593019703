import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "gatsby-plugin-react-intl";
import PageHeader from "./page-header";

import { renderJsonToRichText } from "../helpers/rich-text";
import HeadImage from "./head-image";

const GenericPage = ({ data, articlePosts, intl }) => {
  const description = data?.description ? data?.description : data?.content;
  const richDescription = description
    ? renderJsonToRichText(description, articlePosts)
    : null;

  return (
    <article className="w-full mx-auto xl:max-w-layout-xl xxl:max-w-layout-xxl flex flex-wrap">
      <PageHeader
        data={data}
        className={` ${richDescription && "hidden"} lg:block lg:mr-8 xl:mr-16`}
        locale={intl?.locale}
      />
      {richDescription && (
        <div className="c-rich-text-content w-full lg:w-col c-rich-text lg:mt-40 xxl:mt-56 mb-20 md:mb-36 lg:mb-40">
          <HeadImage data={data}></HeadImage>
          <PageHeader data={data} className={"mt-0 pt-6 sm:pt-0 pb-2 lg:hidden"} locale={intl?.locale} />
          {data?.ingress?.ingress && (
            <p className="c-base-bold mt-4 mb-8">{data?.ingress?.ingress}</p>
          )}
          {richDescription}
        </div>
      )}
    </article>
  );
};

GenericPage.propTypes = {
  articlePosts: {},
  data: {},
};

GenericPage.propTypes = {
  articlePosts: PropTypes.object,
  data: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(GenericPage);
